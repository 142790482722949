import React from 'react';
import { Card, Col, Container, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import CardHeader from 'react-bootstrap/esm/CardHeader';
import TestimoniesCarouselRIV from '../../../components/parts/TestimoniesCarouselRIV';
import '../../RealRental/pages/home.scss';
import './HomeRIV.scss';
import { aboutRIVData, packageData } from '../../../data/aboutRiv'
import { Helmet } from 'react-helmet';

function HomeRIV() {
    const banner = require('../../../assets/Extra-Banner-RIV.jpg');

    const logo = require('../../../assets/logo.png')
    const img2 = require('../../../assets/dollar-house-19205397.jpg')


    return (
        <>
            {/* <Helmet>
                <meta charSet="utf-8" />
                <title>Home | Rental Investissement - Virtuel</title> */}
                {/* <link rel="canonical" href="http://mysite.com/example" /> */}
            {/* </Helmet> */}
            <div className='home-carousel'>
                {/* <HomeCarousel /> */}
                <img src={banner} />
            </div>

            <section id='slogan'>
                {/* <div className='spacer'></div> */}
                <br />
                <Container className="slogal">
                    <Row>
                        <Col md={8}>
                            <div className='py-5'>
                                <h5 className='text-xl'> LA PROPRIÉTÉ RÉINVENTÉE</h5>
                                <div className='text-lg'>
                                    Tout le monde peut maintenant acheter un bien immobilier virtuel fractionné et Tokenisé entièrement conforme et propulsé par la Blockchain.
                                </div>
                            </div>
                        </Col>

                        <Col md={4}>
                            {/* <img src={} /> */}
                            <img className='w-100' src={img2} />
                        </Col>
                    </Row>
                </Container>

                <hr className='half-hr' />
            </section>

            {/* <hr /> */}
            <section>
                <h4 className='text-center'>
                    Les Portefeuilles Tokens RIV
                </h4>
                <br />
                <Container>
                    <Row className='packages-riv'>
                        {packageData.map((data, key) => {
                            console.log(key)
                            return (
                                <Col key={key}>
                                    <Card className='text-center'>
                                        <CardHeader className={data.class}>
                                            {data.nom}
                                        </CardHeader>
                                        <Row className='p-0 py-1 text-center'>
                                            <Col><strong>Prix achat</strong></Col>
                                            <Col><strong>Loyer</strong></Col>
                                        </Row>
                                        {data.prices.map((price, key2) => {
                                            console.log(key2)
                                            return (
                                                <Row className='py-1 text-center row-price' key={key2}>
                                                    <Col>{price.cash} $</Col>
                                                    <Col>{price.rent} $</Col>
                                                </Row>
                                            );
                                        })}
                                    </Card>
                                </Col>
                            );
                        })}
                    </Row>
                </Container>
            </section>
            {/* <hr /> */}

            <section>
                <hr className='half-hr' />
                <Container>
                    <div className='intra-container'>
                        <h5>
                            Ils partagent leurs expériences avec nous.
                            <p>
                                Découvrez les témoignages des investisseurs:
                            </p>

                            <br />
                        </h5>
                        <TestimoniesCarouselRIV />
                    </div>
                </Container>
                <br />
            </section>
            <div className='spacer'></div>
        </>
    )
}

export default HomeRIV