/* eslint-disable no-undef */
import React, { useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import emailjs, { init } from "@emailjs/browser";
import './InvestForm.scss';
import ToastAutoHide from "./ToastAutoHide";

class InvestForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            fname: '',
            lname: '',
            email: '',
            research: '',
            city: '',
            budget: '',
            message: '',
            mailSent: false,
            error: null,
        }
    }

/*
    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        //Budget
        if (!fields["message"]) {
            formIsValid = false;
            errors["message"] = "Cannot be empty";
        }

        //Budget
        if (!fields["budget"]) {
            formIsValid = false;
            errors["budget"] = "Cannot be empty";
        }

        //City
        if (!fields["city"]) {
            formIsValid = false;
            errors["city"] = "Cannot be empty";
        }

        //Research
        if (!fields["research"]) {
            formIsValid = false;
            errors["research"] = "Cannot be empty";
        }

        //LName
        if (!fields["lname"]) {
            formIsValid = false;
            errors["lname"] = "Cannot be empty";
        }

        if (typeof fields["lname"] !== "undefined") {
            if (!fields["lname"].match(/^[a-zA-Z]+$/)) {
                formIsValid = false;
                errors["lname"] = "Only letters";
            }
        }

        //FName
        if (!fields["fname"]) {
            formIsValid = false;
            errors["fname"] = "Cannot be empty";
        }

        if (typeof fields["fname"] !== "undefined") {
            if (!fields["fname"].match(/^[a-zA-Z]+$/)) {
                formIsValid = false;
                errors["fname"] = "Only letters";
            }
        }

        //Email
        if (!fields["email"]) {
            formIsValid = false;
            errors["email"] = "Cannot be empty";
        }

        if (typeof fields["email"] !== "undefined") {
            let lastAtPos = fields["email"].lastIndexOf("@");
            let lastDotPos = fields["email"].lastIndexOf(".");

            if (
                !(
                    lastAtPos < lastDotPos &&
                    lastAtPos > 0 &&
                    fields["email"].indexOf("@@") == -1 &&
                    lastDotPos > 2 &&
                    fields["email"].length - lastDotPos > 2
                )
            ) {
                formIsValid = false;
                errors["email"] = "Email is not valid";
            }
        }

        this.setState({ errors: errors });
        return formIsValid;
    }
    */

    handleFormSubmit = (e) => {
        let formIsValid = true;
        e.preventDefault();
        // console.log(e);
        // console.log(this.state);
        // console.log(e.target);

        // if (this.handleValidation()) {
            // alert("Form submitted");
            
            emailjs.sendForm('service_7l0kp03', 'template_3wipcsefh', e.target, '_BpmZMgXxhb-3rvyX')
                .then((result) => {
                    // console.log(result.text);
                    if (result.text == 'OK') {
                        this.setState({
                            mailSent: true
                        })
                        this.setState({
                            fname: '',
                            lname: '',
                            email: '',
                            research: '',
                            city: '',
                            budget: '',
                            message: '',
                            mailSent: false,
                            error: null
                        });

                    }
                }, (error) => {
                    // console.log(error.text);
                });
            
        // } else {
        //     alert("Form has errors.");
        // }
    };

    render() {
        return (
            <>

                <Form onSubmit={this.handleFormSubmit}>
                    <h5 className="form-field">Parlez nous de vous</h5>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Prénom</Form.Label>
                        <Form.Control type="text" name="firstname" placeholder="Votre Prénom ..."
                            value={this.state.fname}
                            onChange={e => this.setState({ fname: e.target.value })} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Nom</Form.Label>
                        <Form.Control type="text" name="lastname" placeholder="Votre Nom ..."
                            value={this.state.lname}
                            onChange={e => this.setState({ lname: e.target.value })} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control type="email" name="email" placeholder="name@example.com"
                            value={this.state.email}
                            onChange={e => this.setState({ email: e.target.value })} />
                    </Form.Group>

                    <h5 className="form-field">A propos de votre projet d'investissement locatif</h5>

                    <Form.Label>Que cherchez-vous ?*</Form.Label>
                    <Form.Select aria-label="Default select example" name="research"
                        value={this.state.research}
                        onChange={e => this.setState({ research: e.target.value })} >
                        <option selected="selected" disabled="true" value=''>Choisir une option</option>
                        <option >Maison</option>
                        <option >Appartement</option>
                    </Form.Select>

                    <Form.Label>La ville dans laquelle vous souhaitez investir*</Form.Label>
                    <Form.Select aria-label="Default select example" name="city"
                        value={this.state.city}
                        onChange={e => this.setState({ city: e.target.value })} >
                        <option selected="selected" disabled="true" value=''>Les villes couvertes</option>
                        <option >Johannesburg</option>
                        <option >Cape Town</option>
                        <option >Pretoria</option>
                        <option >Durban</option>
                        <option >Port Élisabeth</option>

                    </Form.Select>

                    <Form.Label>Votre budget*</Form.Label>
                    <Form.Select aria-label="Default select example" name="budget"
                        value={this.state.budget}
                        onChange={e => this.setState({ budget: e.target.value })}>
                        <option selected="selected" disabled="true" value=''>Sélectionner un budget</option>
                        <option >50.000 $ - 70.000$</option>
                        <option >80.000 $ - 100.000$</option>
                        <option >100.000 $ - 150.000$</option>
                        <option >150.000 $ - 200.000$</option>
                        <option >autre</option>
                    </Form.Select>



                    <Form.Label>Votre message</Form.Label>
                    <Form.Control as="textarea" name="message" rows={3} placeholder="Écrire ..."
                        value={this.state.message}
                        onChange={e => this.setState({ message: e.target.value })} />
                    <p>
                        <br />
                    </p>
                    {(this.state.fname && this.state.lname && this.state.email && this.state.research && this.state.city && this.state.budget && this.state.message) &&
                        <Button type="submit">Envoyer</Button>
                    }
                    
                </Form>

                {this.state.mailSent &&
                    <ToastAutoHide />
                }

                <div className="spacer"></div>

            </>
        )
    }
}

export default InvestForm