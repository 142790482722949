import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import Img from 'react-optimized-image';
import MultiCarousel from '../../../components/parts/MultiCarousel';
import TestimoniesCarousel from '../../../components/parts/TestimoniesCarousel';

// import HomeCarousel from '../../../components/parts/HomeCarousel';
import './home.scss';

function Home() {
    const banner = require('../../../assets/Extra-Banner-RI.jpg');
    // const logo = require('../../../assets/logo.png');
    return (
        <>
            {/* <Helmet>
          <meta charSet="utf-8" />
          <title>Home | Rental Investissement - Physique</title>
          {/* <link rel="canonical" href="http://mysite.com/example" /> */}
            {/* </Helmet> */}
            <div className='home-carousel'>
                {/* <HomeCarousel /> */}
                <img src={banner} />
            </div>
            <section id='section-1'>
                <Container className='w-90'>
                    <h1 className='heading heading-medium text-center'>
                        GAGNEZ JUSQU'A 1000$ DE LOYER EN INVESTISSANT DANS L’IMMOBILIER LOCATIF <br />EN AFRIQUE DU SUD
                    </h1>
                    <br />
                    {/* <div className='locations'>

                    <p>
                    Johannesburg,  Cape town, Pretoria,  Durban, Port Élisabeth
                            </p>
                    </div> */}
                    <Row className='locations'>
                        <Col>
                            <p className='text-center'>
                                Johannesburg
                            </p>
                        </Col>
                        <Col>
                            <p className='text-center'>
                                Cape Town
                            </p>
                        </Col>
                        <Col>
                            <p className='text-center'>
                                Pretoria
                            </p>
                        </Col>
                        <Col>
                            <p className='text-center'>
                                Durban
                            </p>
                        </Col>
                        <Col>
                            <p className='text-center'>
                                Port Élisabeth
                            </p>
                        </Col>
                    </Row>

                </Container>
            </section>

            <section>
                {/* <hr /> */}
                {/* <br /> */}
                <Container>

                    <Row>
                        <Col md={3}></Col>
                        <Col md={6} className='about-RIR '>
                            <div>
                                <h1 className='text-center'>A propos de nous</h1>
                                <p className='text-center'>
                                    Rental Investment est un service immobilier dédié aux non-résidents, Sud-Africains et étrangers, leur permettant d'investir en Afrique du Sud sans se déplacer forcément.
                                    Vous n’avez rien à faire sinon prendre des décisions et nous laisser faire le reste.
                                </p>
                            </div>
                        </Col>
                        <Col md={3}></Col>
                    </Row>
                </Container>
                <br />
                <hr />
            </section>

            <section>
                <MultiCarousel />
            </section>

            <section>
                <hr />
                <br />
                <Container>
                    <div className='intra-container'>
                        <h5 className='text-center'>
                            Ils partagent leurs expériences avec nous.
                            <p className='text-center'>
                                Découvrez les témoignages de nos heureux propriétaires :
                            </p>

                            <br />
                        </h5>
                        <TestimoniesCarousel />
                    </div>
                </Container>
                <br />
            </section>

            <section id='get-quote'>
                <div className='section-wrapper'>
                    <Container>
                        <div className="flex-box">
                            <Row>
                                <Col>
                                    <img src={require('../../../assets/logo-white.png')} />
                                </Col>

                                <Col>
                                    <div className='wrapper-box'>
                                        {/* <GetQuoteForm /> */}
                                        <h5 className='text-center'>Investissement Locatif</h5>
                                        <ul>
                                            <li className='text-center'>Nous vous guidons</li>
                                            <li className='text-center'>Nous vous conseillons</li>
                                        </ul>

                                        <img src={require('../../../assets/586f912d-5e56-427f-89f9-d032bf0392f5.jpg')} />
                                    </div>
                                </Col>
                            </Row>

                        </div>
                    </Container>
                </div>
            </section>
        </>
    )
}

export default Home