import React, { useState } from 'react'
import { Button, Col, Container } from 'react-bootstrap'
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { refundData } from '../../../data/aboutRiv'
import { Helmet } from 'react-helmet';

function CapitalCreditAndRepayment() {
    const [show, setShow] = useState(false);
    const [success, setSuccess] = useState(false);
    const [ErrorMessage, setErrorMessage] = useState("");
    const [orderID, setOrderID] = useState(false);

    // creates a paypal order
    const createOrder = (data, actions) => {
        return actions.order
            .create({
                purchase_units: [
                    {
                        description: "Sunflower",
                        amount: {
                            currency_code: "USD",
                            value: 20,
                        },
                    },
                ],
                // not needed if a shipping address is actually needed
                application_context: {
                    shipping_preference: "NO_SHIPPING",
                },
            })
            .then((orderID) => {
                setOrderID(orderID);
                return orderID;
            });
    };

    // check Approval
    const onApprove = (data, actions) => {
        return actions.order.capture().then(function (details) {
            const { payer } = details;
            setSuccess(true);
        });
    };
    //capture likely error
    const onError = (data, actions) => {
        setErrorMessage("An Error occured with your payment ");
    };
    return (
        <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Paiement, Crédit et Remboursement | Rental Investissement - Virtuel</title>
          {/* <link rel="canonical" href="http://mysite.com/example" /> */}
        </Helmet>
            <section className='wide-section'>
                <div className='header-wide credit-wide'>
                    {/* <h1>Nous Contacter </h1> */}
                    <h1>Paiement, Crédit et Remboursement</h1><br />
                </div>
                <Container>
                    <div className='spacer'></div>
                </Container>

                <Container>
                    {refundData.map((data, key) => {
                        console.log(key)
                        return (
                            <div key={key}>
                                <h5 > {data.title} </h5>
                                <div dangerouslySetInnerHTML={{ __html: data.answer }}></div>
                                {key == 0 &&
                                    <>
                                        <Col md={4}>
                                            <PayPalScriptProvider options={{ "client-id": "Aa6Ueu1ltr56mYYwdQJ93HwWILP_441n1KKrZJ1W8Qb7m63S8L8vkMyEysS2nhxloNcHt8swVPndlBNc" }}>
                                                <Button type="submit" onClick={() => setShow(true)}>
                                                    Je suis intéressé (e)
                                                </Button>
                                                {show ? (
                                                    <>
                                                    <div className='mt-4 mb-7'></div>
                                                        <PayPalButtons
                                                            style={{ layout: "vertical" }}
                                                            createOrder={createOrder}
                                                            onApprove={onApprove}
                                                        />
                                                    </>
                                                ) : null}
                                            </PayPalScriptProvider>
                                        </Col>
                                        <br />
                                    </>
                                }
                            </div>
                        );
                    })}
                </Container>
                <div className='spacer'></div>
            </section>
        </>
    )
}

export default CapitalCreditAndRepayment