import React, { useRef, useState } from "react";
import { Button, Form, ListGroup, Toast } from "react-bootstrap";
import emailjs from 'emailjs-com';
import axios from 'axios';
import ToastAutoHide from "./ToastAutoHide";



// export default function GetQuoteForm() {

//     function sendEmail(e) {
//       e.preventDefault();

//       emailjs.sendForm('service_7l0kp03', 'template_y7i6czp', e.target, '_BpmZMgXxhb-3rvyX')
//       .then((result) => {
//             console.log(result.text);
//         }, (error) => {
//             console.log(error.text);
//         });
//     }

//     return (
//       <form className="contact-form" onSubmit={sendEmail}>
//         <input type="hidden" name="contact_number" />
//         <label>Name</label>
//         <input type="text" name="user_name" />
//         <label>Email</label>
//         <input type="email" name="user_email" />
//         <label>Message</label>
//         <textarea name="message" />
//         <input type="submit" value="Send" />
//       </form>
//     );
//   }
class GetQuoteForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            fname: '',
            lname: '',
            email: '',
            message: '',
            mailSent: false,
            errors: null
        }
    }

/*
    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        //message
        if (!fields["message"]) {
            formIsValid = false;
            errors["message"] = "Cannot be empty";
        }


        //LName
        if (!fields["lname"]) {
            formIsValid = false;
            errors["lname"] = "Cannot be empty";
        }

        if (typeof fields["lname"] !== "undefined") {
            if (!fields["lname"].match(/^[a-zA-Z]+$/)) {
                formIsValid = false;
                errors["lname"] = "Only letters";
            }
        }

        //FName
        if (!fields["fname"]) {
            formIsValid = false;
            errors["fname"] = "Cannot be empty";
        }

        if (typeof fields["fname"] !== "undefined") {
            if (!fields["fname"].match(/^[a-zA-Z]+$/)) {
                formIsValid = false;
                errors["fname"] = "Only letters";
            }
        }

        //Email
        if (!fields["email"]) {
            formIsValid = false;
            errors["email"] = "Cannot be empty";
        }

        if (typeof fields["email"] !== "undefined") {
            let lastAtPos = fields["email"].lastIndexOf("@");
            let lastDotPos = fields["email"].lastIndexOf(".");

            if (
                !(
                    lastAtPos < lastDotPos &&
                    lastAtPos > 0 &&
                    fields["email"].indexOf("@@") == -1 &&
                    lastDotPos > 2 &&
                    fields["email"].length - lastDotPos > 2
                )
            ) {
                formIsValid = false;
                errors["email"] = "Email is not valid";
            }
        }

        this.setState({ errors: errors });
        return formIsValid;
    }
*/

    handleFormSubmit = (e) => {
        e.preventDefault();
        // console.log(e);
        // console.log(this.state);

        // if (this.handleValidation()) {
        //     alert("Form submitted");
            emailjs.sendForm('service_7l0kp03', 'template_y7i6czp', e.target, '_BpmZMgXxhb-3rvyX')
                .then((result) => {
                    // console.log(result.text);
                    if (result.text == 'OK') {
                        <ToastAutoHide />
                        this.setState({
                            mailSent: true
                        })
                        this.setState({
                            fname: '',
                            lname: '',
                            email: '',
                            message: '',
                            mailSent: false,
                            errors: null
                        });

                    }
                }, (error) => {
                    console.log(error.text);
                });
        // } else {
        //     alert("Form has errors.");
        // }
    };

    render() {
        return (
            <>
                <Form onSubmit={this.handleFormSubmit}>
                    <Form.Group className="mb-3" >
                        <Form.Control type="text" id="fname" name="firstname" placeholder="Votre Prénom ..."
                            value={this.state.fname}
                            onChange={e => this.setState({ fname: e.target.value })} />
                            <Form.Control.Feedback>
                            {/* <span style={{ color: "red" }}>{this.state.errors["fname"]}</span> */}
                            </Form.Control.Feedback>
                    </Form.Group>


                    <Form.Group className="mb-3" >

                        <Form.Control type=" text" id="lname" name="lastname" placeholder="Votre Nom ..."
                            value={this.state.lname}
                            onChange={e => this.setState({ lname: e.target.value })} />
                    </Form.Group>

                    <Form.Group className="mb-3" >
                        <Form.Control type="email" id="email" name="email" placeholder="Votre Mail ..."
                            value={this.state.email}
                            onChange={e => this.setState({ email: e.target.value })} />
                    </Form.Group>

                    <Form.Group className="mb-3" >
                        <Form.Label>Votre message</Form.Label>
                        <Form.Control as="textarea" rows={3} id="message" name="message" placeholder="Write something.."
                            onChange={e => this.setState({ message: e.target.value })}
                            value={this.state.message} />
                    </Form.Group>

                    {(this.state.fname && this.state.lname && this.state.email && this.state.message) &&
                        <Button type="submit">Envoyer</Button>
                    }
                    {/* <input type="submit" value="Envoyer" /> */}


                </Form>

                {this.state.mailSent &&
                    <ToastAutoHide />
                }
                {/* <ToastAutoHide /> */}

            </>
        )
    }
}

export default GetQuoteForm