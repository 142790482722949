import React from 'react';
import logo from './logo.svg';
import './App.scss';

import { Routes, Route } from "react-router-dom"

import ErrorPage from './error-page';
import Landing from './masterPages/Landing';
// import About from './masterPages/RealRental/pages/About';
import OurMission from './masterPages/RealRental/pages/About/OurMission';
import OurObjectives from './masterPages/RealRental/pages/About/OurObjectives';
import OurServices from './masterPages/RealRental/pages/About/OurServices';
import FAQ from './masterPages/RealRental/pages/FAQ';
import Home from './masterPages/RealRental/pages/home';
import Investir from './masterPages/RealRental/pages/investir';
import { Policy } from './masterPages/RealRental/pages/Policy';
import RealRental from './masterPages/RealRental/RealRental';
import ContactUs from './masterPages/RealRental/pages/ContactUs';
import InvestissementLocatif from './masterPages/RealRental/pages/InvestissementLocatif';
import HomeRIV from './masterPages/VirtualRental/pages/HomeRIV';
import VirtualRental from './masterPages/VirtualRental/VirtualRental';
import RentalInvestmentVirtual from './masterPages/VirtualRental/pages/RentalInvestmentVirtual';
import Advantages from './masterPages/VirtualRental/pages/Advantages';
import RivAffiliateProgram from './masterPages/VirtualRental/pages/RivAffiliateProgram';
import CapitalCreditAndRepayment from './masterPages/VirtualRental/pages/CapitalCreditAndRepayment';
import PoliciesRIV from './masterPages/VirtualRental/pages/PoliciesRIV';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';
import { ComingSoon } from './masterPages/ComingSoon/ComingSoon';

function App() {

  if (window.location.protocol == 'http:') {

    console.log("you are accessing us via "
      + "an insecure protocol (HTTP). "
      + "Redirecting you to HTTPS.");

    window.location.href =
      window.location.href.replace(
        'http:', 'https:');
  }
  else if
    (window.location.protocol == "https:") {
    console.log("you are accessing us via"
      + " our secure HTTPS protocol.");
  }

  const TRACKING_ID = "G-BSVX428BKG"; // OUR_TRACKING_ID
  // ReactGA.initialize(TRACKING_ID);
  ReactGA.initialize(
    [
      {
        trackingId: 'G-BSVX428BKG',
      }
    ],
    { debug: true, alwaysSendToDefaultTracker: false }
  )

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Rental Investissement</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
      </Helmet>
      <Routes>
        {/* <Route path='/' element={ <Landing/> }></Route>
      <Route path='/' element={<Landing />}> */}

        <Route index element={<Landing />} />
        {/* </Route> */}
        {/* REAL */}
        <Route path="rental-real" element={<RealRental />} >
          <Route index element={<Home />} />
          <Route path="our-mission" element={<OurMission />} />
          <Route path="our-objectives" element={<OurObjectives />} />
          <Route path="our-services" element={<OurServices />} />
          <Route path="locative-investment" element={<InvestissementLocatif />} />
          <Route path="investir" element={<Investir />} />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="faq" element={<FAQ />} />
          <Route path="policy" element={<Policy />} title='Potitique de confidentialité' />
        </Route>
        {/* VIRTUAL */}
        <Route path="rental-virtual" element={<VirtualRental />} >
        {/* <Route path="rental-virtual" element={<ComingSoon />} > */}
          <Route index element={<HomeRIV />} />
          <Route path="rental-investment-virtual" element={<RentalInvestmentVirtual />} />
          <Route path="advantages" element={<Advantages />} />
          <Route path="riv-affiliate-program" element={<RivAffiliateProgram />} />
          <Route path="capital-credit-and-repayment" element={<CapitalCreditAndRepayment />} />
          <Route path="policy" element={<PoliciesRIV />} />
        </Route>

        {/* <Route path='coming'></Route> */}
        {/* BAD URL */}
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  );
}

export default App;
