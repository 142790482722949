import React from 'react'
import { Container } from 'react-bootstrap'
import { Helmet } from 'react-helmet'

export const Policy = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Politique de confidentialité | Rental Investissement - Physique</title>
                {/* <link rel="canonical" href="http://mysite.com/example" /> */}
            </Helmet>
            <section id='faq'>
                <Container className='w-90'>
                    <div>

                        <h1>Politique de confidentialité</h1>

                        <p> <h2>Périmètre</h2> <br />
                            Cette politique de confidentialité a pour objectif de vous aider à comprendre la manière dont nous (Rental Investment) traitons les données personnelles que vous nous transmettez via :<br />
                            • Le site www.rental-investment.com (“le Site”)<br />
                            • La plateforme app.rental-investment.com (“la Plateforme”)<br />
                            • Les outils rental-investment, mails, et autres applications que nous sommes amenés à utiliser dans le cadre de vos transactions immobilières<br />
                            • Engagements : <br />
                            Nous mettons tout en œuvre pour vous fournir des informations adaptées tout en respectant la confidentialité de vos données personnelles. Nous nous sommes donc fixé de ne jamais revendre vos données personnelles. Nous nous engageons solennellement à ne jamais revendre vos données qu'elle qu'en soit la raison. Nous gagnons notre vie en construisant et en gérant une plateforme de transactions immobilières, pas en revendant les données de nos clients. Cette politique de confidentialité détaille les étapes qui nous permettent de respecter la confidentialité de vos données. Cela étant, si, après avoir lu notre politique de confidentialité, vous avez la moindre question au sujet de la confidentialité de vos données, envoyez-nous un mail à : <a href='mailto:contact@rental-investment.com'>contact@rental-investment.com</a> <br /><br />
                            • Récupération de vos données personnelles:<br />
                            Vous serez certainement amené à nous communiquer des informations personnelles dans de nombreuses circonstances, soit en créant un compte sur notre plateforme, soit pour mettre à jour votre compte ou encore lors des opérations de vos transactions financières. Ces informations peuvent être des données personnelles, professionnelles ou des documents vous concernant ou concernant l’entité qui se porte acquéreur (couple, société, personnes physiques ou morales). Compte Rental Investment. Certaines données peuvent être associées à votre compte Rental Investment comme les biens que vous préférez ou les offres que vous faites. Conversation téléphonique. Nous pouvons être amenés à enregistrer les conversations téléphoniques que vous avez avec nous. Nous mettons en place ces enregistrements pour améliorer la qualité de service de nos agents dans le but de vous fournir un service toujours meilleur.<br />

                            • Comte Rental Investment:<br /><br />
                            Certaines données peuvent êtres associées à votre compte Rental Investment comme les biens que vous préférez ou les offres que vous faites.<br />
                            • Comte Rental Investment:<br /><br />
                            Nous pourrions enregistrer les conversations téléphoniques que vous aurez avec nous. L'objectif de cet enregistrement est de nous permettre d'améliorer la qualité des services de nos agents dans le but de vous fournir un service toujours meilleur

                            </p>
                        <p>
                            <h2>Utilisation de vos données</h2><br />
                            Nous utilisons vos données que dans l’objectif pour lesquels vous nous les avez transmis ou pour lesquels nous les avons collectés. Par exemple, nous utiliserons vos données pour : <br />
                            • Créer et mettre à jour votre compte Rental Investment ou mettre à jour vos préférences de communication;<br />
                            • Améliorer l’expérience utilisateur;<br />
                            • Effectuer des recherches et des analyses;<br />
                            • Personnaliser et adapter les contenus qui vous sont envoyés dans le cadre des services de Rental Investment;<br />
                            • Vous contacter par téléphone, email ou autre moyen de communication;<br />
                            • Pour faciliter le marketing ou les transactions pour lesquels vous vous êtes inscrit;<br />
                            • Vous envoyer des informations par lesquelles vous pouvez être intéressé;<br />
                            • Pour vous alerter des nouvelles opportunités ou des événements liés à vos transactions;<br />
                            • Pour tout autre sujet lié à votre compte ou à votre transaction;<br />
                            • Se conformer à la loi, notamment dans le cadre de la lutte contre le blanchiment d’argent et du financement du terrorisme.<br />
                            <br />
                            <h2>Transfert de vos données</h2><br />
                            Nous serons emmenés à transférer vos données et les documents que vous nous avez communiqués dans les cas suivants : Si vous téléchargez des documents ou que vous renseignez des informations sur un bien immobilier, l’agence qui détient un mandat de vente pour ce bien, le propriétaire du bien, le notaire du propriétaire du bien et le notaire qui vous représente pourront avoir accès à ces informations. Ces transferts de données et de documents sont effectués dans le cadre de vos transactions immobilières réalisées via Rental Investment.</p>

                    </div>
                </Container>
            </section>
        </>
    )
}
