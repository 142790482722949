import React, { useState } from 'react';
import { ToastContainer } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Toast from 'react-bootstrap/Toast';
import './ToastAutoHide.scss';

function ToastAutoHide() {
    const [show, setShow] = useState(false);

    return (
        <ToastContainer position='middle-center'>
            {/* <Toast bg='success' onClose={() => setShow(false)} id='toaster'> */}
                <Toast bg='info' id='toaster' onClose={() => setShow(false)} show={show} delay={3000} autohide>
                <Toast.Header>
                    <img
                        src="holder.js/20x20?text=%20"
                        className="rounded me-2"
                        alt=""
                    />
                    <strong className="me-auto">Rental Investment</strong>
                    <small>À l'instant</small>
                </Toast.Header>
                <Toast.Body>Votre message a été envoyé</Toast.Body>
            </Toast>
        </ToastContainer>
    );
}

export default ToastAutoHide