import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

// require('env2')('.env');

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import { Navigation, Pagination, Autoplay } from 'swiper';

import './TestimoniesCarouselRIV.scss';
import { Card } from 'react-bootstrap';

const config = {
  base: 'appwMEXsNdiApbdAI',
  apiKey: 'keytFNNyh6QrMurDu'
}


const request = new Request(`https://api.airtable.com/v0/${config.base}/TestimoniesRIV`, {
  method: 'get',
  headers: new Headers({
    'Authorization': `Bearer ${config.apiKey}`
  })
});

class TestimoniesCarouselRIV extends React.Component {

  constructor(props) {
    super(props);
    this.state = { records: [] };
    this.fetchAirtable = this.fetchAirtable.bind(this);
  }

  async componentDidMount() {
    await this.fetchAirtable()
  }

  async fetchAirtable() {
    var resp = await fetch(request).catch(err => { console.log(err) })
    if (resp.status >= 200 && resp.status < 300) {
      var json = await resp.json()
      const { records } = json;
      this.setState({ records });
    }
  }

  render() {
    var { records } = this.state
    return (
      <>

        <Swiper
          // install Swiper modules
          slidesPerView={1}
          spaceBetween={10}
          centeredSlides={true}
          // Disable preloading of all images
          preloadImages={false}
          effect={'fade'}
          // Enable lazy loading
          lazy={true}
          loop={true}
          autoplay={{
            delay: 3500,
            disableOnInteraction: true,
            pauseOnMouseEnter: true,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper2"
        // onSwiper={(swiper) => console.log(swiper)}
        // onSlideChange={() => console.log('slide change')}
        >

          {records.map((testimony, key) =>
            <SwiperSlide id={testimony.id} key={key}>
              <div className='testimonial-wrapper d-none d-md-block'>
                <div className="testimonial-container">
                  <div className="testimonial-cover" >
                    <div>
                      <img src={testimony.fields.SubmitterCover[0].thumbnails.large.url} />
                    </div>
                  </div>
                  <div className="testimonial-content">
                    <div>
                      {/* <p className='testimonial-author'>Josué Songololo</p>
                  <blockquote className='testimonial-quote'>
                    J'ai utilisé RI pour acquérir un bien immobilier et je suis devenu un heureux propriétaire désormais
                  </blockquote> */}
                      <div className="quote--container">
                        <p className="quote quoteRIV">
                          «{testimony.fields.Notes}»
                        </p>
                        <p className="quote--author">&ndash; {testimony.fields.SubmittedBy} </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div className='testimonial-wrapper-vertical d-md-none'>
                <Card style={{ width: '22.5rem' }} >
                  <Card.Img variant="top" src={testimony.fields.SubmitterCover[0].thumbnails.large.url} />
                  <Card.Body>
                  <div className="testimonial-content">
                    <div className='quote--div-holder'>
                      {/* <p className='testimonial-author'>Josué Songololo</p>
                  <blockquote className='testimonial-quote'>
                    J'ai utilisé RI pour acquérir un bien immobilier et je suis devenu un heureux propriétaire désormais
                  </blockquote> */}
                      <div className="quote--container">
                        <p className="quote">
                          «{testimony.fields.Notes}»
                        </p>
                        <p className="quote--author">&ndash; {testimony.fields.SubmittedBy} </p>
                      </div>
                    </div>
                  </div>
                  </Card.Body>
                </Card>
              </div>
            </SwiperSlide>
          )}

        </Swiper>
      </>
    )
  }
}

export default TestimoniesCarouselRIV