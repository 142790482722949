import React from 'react'
import { Container } from 'react-bootstrap'
import { Helmet } from 'react-helmet'

function PoliciesRIV() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Conditions d'utilisation | Rental Investissement - Virtuel</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
      </Helmet>
      <section id='our-mission'>
        <Container className='w-90'>
          <div>

            <h1>Conditions d'utilisation</h1>

            <p>VEUILLEZ LIRE ATTENTIVEMENT LES PRÉSENTES CONDITIONS D'UTILISATION DE RENTAL INVESTMENT VIRTUEL. CES CONDITIONS D'UTILISATION ET LES AUTRES ACCORDS SUR LES SITES RÉGISSENT VOTRE UTILISATION DE NOTRE SITE WEB OU DE NOTRE APPLICATION MOBILE (LES SITES). ELLES COMPRENNENT ÉGALEMENT DES DIVULGATIONS IMPORTANTES ET DES INFORMATIONS RÉGLEMENTAIRES QUI SONT ASSOCIÉES AUX SERVICES DISPONIBLES SUR LES SITES. ILS SONT TOUS IMPORTANTS ET AFFECTENT VOS DROITS LÉGAUX. NOUS VOUS PRIONS DONC LES LIRE ATTENTIVEMENT.</p>
            <p>
              EN ACCÉDANT OU EN UTILISANT LES SITES, VOUS DÉCLAREZ QUE:<br />
              A. VOUS AVEZ LU, COMPRIS ET ACCEPTÉ D'ÊTRE LIÉ PAR LES CONDITIONS DU SITE;<br />
              B. VOUS AVEZ L'ÂGE LÉGAL POUR SIGNER UN CONTRAT CONTRAIGNANT AVEC RENTAL INVESTMENT;<br />
              C. VOUS AVEZ L'AUTORITÉ POUR CONCLURE LES CONDITIONS D'UTILISATION PERSONNELLEMENT OU AU NOM DE L'ENTITÉ QUE VOUS AVEZ NOMMÉE EN TANT QU'UTILISATEUR ENREGISTRÉ, ET POUR LIER CETTE ENTITÉ AUX CONDITIONS DU SITE. LE TERME "VOUS" FAIT RÉFÉRENCE À LA PERSONNE PHYSIQUE OU MORALE, SELON LE CAS, IDENTIFIÉE COMME L'UTILISATEUR ENREGISTRÉ LORSQUE VOUS SOUMETTEZ VOS INFORMATIONS D'ENREGISTREMENT SUR LES SITES. VOUS ACCEPTEZ D'ÊTRE LIÉ PAR LES CONDITIONS DU SITE DÉCRITES DANS LE PRÉSENT DOCUMENT ET PAR TOUTES LES CONDITIONS INCORPORÉES PAR RÉFÉRENCE. VOTRE ACCÈS ET VOTRE UTILISATION DES SITES CONSTITUENT VOTRE ACCEPTATION ET VOTRE ACCORD POUR RESPECTER CHACUNE DES CONDITIONS DU SITE ÉNONCÉES CI-DESSOUS. SI VOUS N'ACCEPTEZ PAS TOUTES LES CONDITIONS DU SITE, N'UTILISEZ PAS NOTRE SITE WEB OU NOTRE APPLICATION MOBILE.
            </p>

            <p>Les présentes Conditions des sites s'appliquent uniquement à votre accès et à votre utilisation du site Web ou de l'application mobile de Rental Investment. Les présentes Conditions ne modifient en aucune façon les conditions de tout autre accord que vous pourriez avoir avec Rental Investment pour des produits, des services ou autres. Si vous utilisez les sites au nom d'une entité, vous déclarez et garantissez que vous êtes autorisé à accepter les conditions au nom de cette entité, et que celle-ci accepte d'être responsable envers Rental Investment de toute violation des conditions.</p>

            <p>Rental Investment se réserve le droit de changer ou de modifier les conditions des sites ou toute politique ou directive, à tout moment et à sa seule discrétion. Si Rental Investment apporte des modifications à ces conditions, nous vous en informerons par exemple en publiant un avis sur les sites ou en mettant à jour la date de " dernière mise à jour ", qui peut être indiquée sur les sites. Votre utilisation continue de nos Sites après la publication de changements ou de modifications confirmera votre acceptation de ces changements ou modifications. Par conséquent, vous devez fréquemment consulter les conditions des sites et les politiques applicables chaque fois que vous accédez aux sites et au moins tous les trente (30) jours pour vous assurer que vous comprenez les conditions qui s'appliqueront à vous et à votre utilisation des sites. Si vous n'acceptez pas les conditions modifiées, vous devez cesser d'utiliser les sites.</p>

            <h6> 1. Légalité</h6>

            <p>L'utilisation du site et l’Application Rental Investment en violation des lois applicables est expressément interdite. Vous êtes seul responsable de la vérification que votre utilisation des sites et la réalisation des transactions envisagées par les sites sont autorisées dans votre juridiction et sont conformes à toutes les lois, réglementations, ordonnances et décisions de justice applicables. Si vous avez des questions concernant la légalité dans votre juridiction, veuillez consulter votre conseiller juridique. Lorsque vous accédez aux sites ou que vous vous y inscrivez, vous êtes seul responsable du respect de toutes les règles et réglementations applicables aux activités liées aux sites.</p>

            <h6> 2. Aucun conseil financier</h6>

            <p>Rental Investment n'est pas un portail de financement ou un conseiller en investissement enregistré et ne mène aucune activité qui nécessiterait un tel enregistrement. Aucune des informations contenues sur le Site ne constitue une recommandation par Rental Investment ou ses affiliés d'acheter ou de vendre des titres ou d'autres actifs. Les informations contenues sur les sites ont été préparées sans référence aux besoins d'investissement ou à la situation financière d'un utilisateur particulier. Les sites n'offrent pas de conseils financiers et ne recommandent pas d'investissements. Nous vous recommandons de consulter des professionnels qualifiés avant de réaliser des investissements ou de prendre des décisions financières. Toute information disponible sur le site n'est pas proposée en tant que conseil financier et ne doit pas servir de base à des activités financières réelles.</p>

            <h6> 3. Services et Offres de Tokens RIV</h6>

            <p>Parmi les Services, ces Sites présentent des informations concernant des investissements potentiels dans des participations de société à responsabilité limitée (Intérêts) de diverses séries de Tokens RIV. Ces Intérêts de Série, qui sont des titres sur les valeurs mobilières, seront représentés par des cartes de propriétés avec séries numériques, qui sont des contrats intelligents basés sur l’immobilier virtuel.<br />
              En participant à une offre de Tokens RIV par le biais des sites, vous acceptez et comprenez que chaque offre a ses propres conditions et règles et qu'il est de votre responsabilité d'examiner attentivement toutes les conditions, règles, facteurs de risque et de décider par vous-même si vous les acceptez ou pas.<br />
              Rental Investment vous donnera la possibilité de désigner les opportunités d'investissement auxquelles vous souhaitez participer. Cependant, votre décision de participer à une offre de Série de Tokens RIV ne signifie pas que vous serez autorisé à participer à cette offre. Nos décisions d'autoriser votre participation sont soumises, entre autres, à la démonstration à notre satisfaction que vous remplissez les conditions légales de participation.<br />
              Les Series Tokens RIV proposées sont de nature hautement spéculatives, elles impliquent un degré élevé de risque et ne doivent être achetées que par des personnes responsables et conscientes des implications y relatives.</p>

            <h6> 4. Données d'enregistrement, sécurité du compte, politique de confidentialité et portefeuille Tokens</h6>

            <p>Afin d'utiliser le Site et les Services, vous devrez créer un compte de service (Compte) en vous inscrivant sur l’Application mobile de Rental Investment RIV pour devenir un utilisateur enregistré. Pour vous inscrire, vous devez fournir votre vrai nom, votre adresse, votre numéro de téléphone et votre adresse e-mail, ainsi que d'autres informations qui peuvent être requises de temps à autre. Le droit d'utiliser le site et les services vous est personnel en tant qu'utilisateur ou à l'entreprise pour laquelle vous vous inscrivez. Il n'est pas transférable à une autre personne. En tant qu'utilisateur de notre site et services, vous devez respecter les présentes conditions d'utilisation du site et notre politique de confidentialité.<br />

            En contrepartie de votre utilisation des Sites et des Services et de la création d'un Compte, vous acceptez de :
            <br/>a. fournir des informations exactes, actuelles et complètes vous concernant, comme cela peut être demandé par tout formulaire d'inscription sur le Site (" Données d'inscription ") ;
            <br/>b. maintenir la sécurité de votre mot de passe et de votre identification ; 
            <br/>c. maintenir et mettre à jour rapidement les Données d'inscription, et toute autre information que vous fournissez à Rental Investment, afin qu'elles restent exactes, actuelles et complètes ;
            <br/>d. accepter tous les risques d'accès non autorisé aux Données d'inscription et à toute autre information que vous fournissez à Rental Investment.<br /><br/>
            
              En créant un Compte, vous déclarez et garantissez que toutes les Données d'enregistrement pour le Compte que vous soumettez sont véridiques et exactes. Vous acceptez de ne pas utiliser le Compte d'un autre utilisateur. Vous êtes seul responsable de toute utilisation de votre Compte. Vous acceptez que le fait de partager les Services avec une autre personne ou de permettre à une autre personne d'accéder aux Services par l'intermédiaire de votre Compte constitue une violation des présentes Conditions du Site et peut constituer une fraude ou un vol pour lesquels nous nous réservons tous les droits et recours. Vous acceptez de ne pas autoriser d'autres individus ou entités autres que vos agents autorisés, le cas échéant, à utiliser les sites ou les services via votre compte.<br />
              Vous n'êtes pas tenu par la loi de nous fournir des informations d'identification personnelle. Toutefois, si vous ne fournissez pas les données d'enregistrement ou toute autre information d'identification personnelle demandée, vous ne pourrez pas utiliser certaines fonctionnalités de l’Application ou des Services. Rental Investment utilisera vos informations d'identification personnelle comme indiqué dans la politique de confidentialité de Rental Investment. Vous déclarez et garantissez que vous avez examiné la politique de confidentialité et que vous en acceptez les conditions.</p>

            <h6> 5. Droits d'auteur et licence limitée</h6>

            <p>Sauf indication contraire dans les Sites, les Sites et tout le contenu et autres matériaux sur les Sites, y compris, sans limitation, le logo Rental Investment, et tous les designs, textes, graphiques, images, informations, données, logiciels, fichiers sonores, autres fichiers et la sélection et l'arrangement de ceux-ci (collectivement, les "Matériaux du Site") sont la propriété exclusive de Rental Investment ou de nos concédants ou utilisateurs et sont protégés par les lois Sud-Africaines et internationales sur le copyright.<br /><br/>
              Toute utilisation des sites ou des documents du site autre que celle spécifiquement autorisée par les présentes, sans l'autorisation écrite préalable de Rental Investment, est strictement interdite. Une telle utilisation non autorisée peut également violer les lois applicables, y compris, mais sans s'y limiter, les lois sur les droits d'auteur et les marques commerciales, ainsi que les réglementations et statuts applicables en matière de communication. Sauf mention explicite dans les présentes, rien dans les présentes Conditions des Sites ne doit être interprété comme conférant une licence sur les droits de propriété intellectuelle, que ce soit par estoppel, implication ou autre.</p>

            <h6>6. Politique relative aux contrevenants récidivistes</h6>

            <p>Conformément au Digital Millennium Copyright Act ("DMCA") et à toute autre loi applicable, Rental Investment a adopté une politique consistant à mettre fin à l'accès aux Sites, dans des circonstances appropriées et à la seule discrétion de Rental Investment, aux abonnés ou aux titulaires de comptes, s'ils sont autorisés, qui sont considérés comme des contrevenants répétés. Rental Investment peut également, à sa seule discrétion, limiter l'accès aux Sites et/ou résilier les comptes de tout utilisateur qui enfreint les droits de propriété intellectuelle d'autrui, qu'il y ait ou non infraction répétée.</p>

            <h6>7. Marques commerciales</h6>

            <p>Rental Investment, le logo Rental Investment et tout autre nom de produit ou de service ou slogan contenu dans les sites sont des marques commerciales de Rental Investment et de ses fournisseurs ou concédants. Ils ne peuvent être copiés, imités ou utilisés, en tout ou en partie, sans l'autorisation écrite préalable de Rental Investment ou du détenteur de la marque commerciale concernée. Vous ne pouvez pas utiliser de métabolises ou tout autre "texte caché" utilisant " Rental Investment " ou tout autre nom, marque ou nom de produit ou service de Rental Investment sans notre autorisation écrite préalable. En outre, l'aspect et la convivialité des sites, y compris tous les en-têtes de page, les graphiques personnalisés, les icônes de boutons et les scripts, constituent la marque de service, la marque commerciale et/ou l'habillage commercial de Rental Investment et ne peuvent être copiés, imités ou utilisés, en tout ou en partie, sans notre autorisation écrite préalable. Toutes les autres marques, marques déposées, noms de produits et noms ou logos de sociétés mentionnés sur les sites sont la propriété de leurs détenteurs respectifs. La référence à des produits, services, processus ou autres informations, par nom commercial, marque, fabricant, fournisseur ou autre, ne constitue ni n'implique l'approbation, le parrainage ou la recommandation de ceux-ci par Rental Investment.</p>

            <h6>8. Commentaires</h6>

            <p>Vous reconnaissez et acceptez que toute question, tout commentaire, toute suggestion, tout retour d'information, toute idée, tout plan, toute note, tout dessin, tout matériel original ou créatif ou toute autre information ou matériel concernant les sites, Rental Investment ou les produits ou services généraux de Rental Investment (le " retour d'information ") que vous fournissez sous forme d'e-mail ou d'autres soumissions à Rental Investment , ou tout affichage sur les sites, est (entre vous et Rental Investment ) non confidentiel et devient la propriété exclusive de Rental Investment. Rental Investment détiendra, et vous cédez par la présente à Rental Investment, tous les droits exclusifs, y compris tous les droits de propriété intellectuelle, et aura le droit d'utiliser et de diffuser sans restriction ces commentaires à toutes fins, commerciales ou autres, sans reconnaissance ni compensation pour vous.</p>

            <h6>9. Indemnisation</h6>

            <p>Vous acceptez de défendre, d'indemniser et de dégager de toute responsabilité Rental Investment , ses affiliés, ses entrepreneurs indépendants, ses fournisseurs de services et ses consultants, ainsi que leurs directeurs, employés et agents respectifs, contre toute réclamation, tout dommage, tout coût, toute responsabilité et toute dépense (y compris, mais sans s'y limiter, les honoraires raisonnables d'avocat) découlant de ou liés à tout contenu utilisateur que vous publiez, y compris, mais sans s'y limiter, toute poursuite, demande ou réclamation actuelle ou imminente contre Rental Investment et/ou ses sociétés affiliées, entrepreneurs indépendants, prestataires de services, employés, directeurs ou consultants, découlant de ou liée au contenu de l'utilisateur, à votre conduite, à votre violation des présentes conditions du site ou à votre violation des droits d'un tiers.</p>

            <h6>10. Clause de non-responsabilité</h6>

            <p>Sauf disposition contraire expresse dans un écrit de Rental Investment, les sites, les matériaux des sites qu'ils contiennent et les services fournis sur ou en relation avec ceux-ci (les "services") sont fournis "en l'état" sans garantie d'aucune sorte, expresse ou implicite. Rental Investment décline toute autre garantie, expresse ou implicite, y compris, mais sans s'y limiter, les garanties implicites de qualité marchande, d'adéquation à un usage particulier, de titre et d'absence de contrefaçon concernant les sites et les services, y compris les informations, le contenu et les matériaux qu'ils contiennent. Rental Investment ne déclare pas et ne garantit pas que les matériaux des sites ou des services sont précis, complets, fiables, actuels ou sans erreur. Rental Investment ne déclare ni ne garantit que le site ou ses serveurs sont exempts de virus ou d'autres composants nuisibles.</p>

            <p>Rental Investment n'est pas responsable des erreurs typographiques ou des omissions relatives aux prix, aux textes ou aux photographies. Bien que Rental Investment s'efforce de rendre votre accès et votre utilisation du site et des services sûrs, il ne peut pas et ne garantit pas que le site ou ses serveurs sont exempts de virus ou d'autres composants nuisibles. Par conséquent, vous devez utiliser un logiciel reconnu par l'industrie pour détecter et désinfecter les virus de tout téléchargement.</p>

            <h6>11. Limitation de la responsabilité</h6>

            <p>En aucun cas, Rental Investment, ses directeurs, membres, employés ou agents ne seront responsables de tout dommage direct, spécial, indirect ou consécutif, ou de tout autre dommage de quelque nature que ce soit, y compris, mais sans s'y limiter, la perte d'utilisation, la perte de profits ou la perte de données, que ce soit dans le cadre d'une action contractuelle, délictuelle (y compris, mais sans s'y limiter, la négligence) ou autre, découlant de ou liée de quelque manière que ce soit à l'utilisation ou à l'incapacité d'utiliser le site, les services, le contenu ou les matériaux contenus dans ou accessibles par le site, y compris, mais sans s'y limiter, tout dommage causé par ou résultant de la confiance accordée par l'utilisateur à toute information obtenue de Rental Investment, ou résultant d'erreurs, d'omissions, d'interruptions, de suppressions de fichiers ou de courriers électroniques, d'erreurs, de défauts, de virus, de retards de fonctionnement ou de transmission ou de tout défaut de performance, qu'il résulte ou non de catastrophes naturelles, de défaillances des communications, de vol, de destruction ou d'accès non autorisé aux registres, programmes ou services de Rental Investment. En aucun cas, la responsabilité globale de Rental Investment, que ce soit dans le cadre d'un contrat, d'une garantie, d'un délit (y compris la négligence, qu'elle soit active, passive ou imputée), de la responsabilité du produit, de la responsabilité stricte ou de toute autre théorie, découlant de ou liée à l'utilisation ou à l'incapacité d'utiliser le site ou les services ou aux présentes conditions du site, ne dépassera la compensation que vous payez, le cas échéant, à Rental Investment pour l'accès ou l'utilisation du site.<br />
            
            Il existe des risques associés à l'utilisation d'un protocole blockchain basé sur Internet, y compris, mais sans s'y limiter, les risques liés au matériel, aux logiciels et aux connexions Internet, le risque d'introduction de logiciels malveillants et le risque que des tiers puissent obtenir un accès non autorisé aux informations stockées dans votre compte ou aux actifs de votre portefeuille. Vous acceptez et reconnaissez que Rental Investment ne sera pas responsable des échecs de communication, des perturbations, des erreurs, des distorsions, des retards ou des fraudes de tiers que vous pourriez subir en raison de circonstances indépendantes de notre volonté.<br />
            
            RENTAL INVESTMENT NE SERA PAS RESPONSABLE OU REDEVABLE ENVERS VOUS POUR TOUTE PERTE ET NE PREND AUCUNE RESPONSABILITÉ POUR, ET NE SERA PAS REDEVABLE ENVERS VOUS POUR, TOUT DOMMAGE RÉSULTANT DE TOUTE DÉCISION OU ACTION PRISE PAR VOUS SUR LA BASE DU CONTENU DES SITES RENTAL INVESTMENT OU DES INFORMATIONS DES OFFRES DE LA SÉRIE TOKENS RIV, Y COMPRIS, MAIS SANS S'Y LIMITER, TOUTES PERTES, DOMMAGES OU RÉCLAMATIONS RÉSULTANT DE : 
            <br/>A. D'UNE ERREUR DE L'UTILISATEUR TELLE QU'UN MOT DE PASSE OUBLIÉ, UNE TRANSACTION MAL CONSTRUITE, DES INFORMATIONS DE VIREMENT BANCAIRE OU DES ADRESSES MAL SAISIES ;
            <br/>B. D'UNE PANNE DE SERVEUR OU D'UNE PERTE DE DONNÉES;
            <br/>C. DE FICHIERS DE PORTEFEUILLE CORROMPUS;
            <br/>D. D'UN ACCÈS NON AUTORISÉ AUX SITES OU AUX SERVICES;
            <br/>E. DE TOUTE ACTIVITÉ DE TIERS NON AUTORISÉE, Y COMPRIS, SANS S'Y LIMITER, L'UTILISATION DE VIRUS, DE PHISHING, DE BRUTEFORCING OU D'AUTRES MOYENS DE CYBERATTAQUE CONTRE LES SITES RENTAL INVESTMENT OU TOUT SERVICE ASSOCIÉ, Y COMPRIS LES TOKENS RIV.</p>

            <h6>12. Modifications des sites</h6>

            <p>Rental Investment se réserve le droit de modifier tout contenu du site et de modifier, suspendre ou interrompre les services offerts par le site ou toute caractéristique ou fonctionnalité du site ou des services et sans obligation ou responsabilité envers vous.</p>

            <h6>13. Résiliation</h6>

            <p>Nonobstant les présentes Conditions des sites, Rental Investment se réserve le droit, sans préavis et à sa seule discrétion, de résilier votre droit d'utilisation du site et de bloquer ou d'empêcher votre accès et votre utilisation futurs de l’Application mobile.</p>

            <h6>14. Divisibilité</h6>

            <p>Si une disposition des présentes Conditions des Sites est jugée illégale, nulle ou inapplicable pour quelque raison que ce soit, cette disposition sera considérée comme dissociable des présentes Conditions des Sites et n'affectera pas la validité et l'applicabilité des autres dispositions.<br />
            </p>

          </div>
          <div className='spacer'></div>
        </Container>
      </section>
    </>
  )
}

export default PoliciesRIV