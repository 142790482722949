import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { Outlet } from 'react-router-dom';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
// import Header from '../../components/Header';
import HeaderSecond from '../../components/HeaderSecond';
import Home from './pages/home';
import './RealRental.scss'

function RealRental() {
    const [showTopBtn, setShowTopBtn] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 400) {
                setShowTopBtn(true);
            } else {
                setShowTopBtn(false);
            }
        });
    }, []);
    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    const closeNav = () => {
        var element = document.getElementById('mySidenav');
        if (element.style.width === '250px') {
            console.log(56);
        }
        // if (document.getElementById("mySidenav").style.width == "250px") {
        //     document.getElementById("mySidenav").style.width = "0";
        //     document.body.style.backgroundColor = "white";
        //     console.log('Nav closed');
        // }
        console.log('Nav ', element.style.width);
    }
    console.log(window.location.pathname + window.location.search);
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Rental Investissement - Physique</title>
                {/* <link rel="canonical" href="http://mysite.com/example" /> */}
            </Helmet>
            <div className="App">
                <div className='d-none d-md-block navbar-block navbar-wide'>
                    <Header />
                </div>

                {/* NAVBAR IF PHONE */}
                <div className='d-md-none navbar-block navbar-device'>
                    <HeaderSecond />
                </div>
                <div className='main' id='main' onClick={closeNav} >
                    <Outlet />
                </div>
                <Footer />
            </div>
        </>
    )
}

export default RealRental