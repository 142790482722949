// import React from 'react'
import './HeaderSecond.scss';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';

function HeaderSecond() {
    const logo = require('../assets/logo.png')
    const [showTopBtn, setShowTopBtn] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 400) {
                setShowTopBtn(true);
            } else {
                setShowTopBtn(false);
            }
        });
    }, []);
    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    const openNav = () => {
        document.getElementById("mySidenav").style.width = "250px";
        // document.body.style.backgroundColor = "rgba(0,0,0,0.4)";
        console.log('Nav opened');
    }

    const closeNav = () => {
        document.getElementById("mySidenav").style.width = "0";
        // document.body.style.backgroundColor = "white";
        console.log('Nav closed');
    }

    const onLinkClick = () => {
        goToTop();
        closeNav();
        // hideMenu();
        // ()=>gaEventTracker('call')
    }


    return (
        <>
            {/* NAVBAR IF NOT PHONE */}
            <span className='sm-logo-menu'>
                <img className='' src={logo} /> <span>Rental Investment</span>
            </span>
            <span className='butn-toggle' onClick={openNav}>&#9776;</span>
            <div id="mySidenav" class="sidenav">
                <div className='mySidenav'>
                    <a href="javascript:void(0)" class="closebtn" onClick={closeNav}>&times;</a>

                    {['md'].map((expand) => (
                        <Nav defaultActiveKey="/" className="justify-content-end flex-column">
                            <NavDropdown
                                title="A propos de nous"
                                id={`offcanvasNavbarDropdown-expand-${expand}`}
                                className='about-drop'
                            >
                                <NavDropdown.Item >
                                    {/* <Link to={`our-mission`} onClick={goToTop; hideMenu}> */}
                                    <Link to={`our-mission`} onClick={onLinkClick}>
                                        Notre Mission
                                    </Link>
                                </NavDropdown.Item>

                                <NavDropdown.Item >
                                    <Link to={`our-objectives`} onClick={onLinkClick}>
                                        Nos Objectifs
                                    </Link>
                                </NavDropdown.Item>

                                <NavDropdown.Item >
                                    <Link to={`our-services`} onClick={onLinkClick}>
                                        Nos Services
                                    </Link>
                                </NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Item>
                                {/* <Nav.Link > */}
                                {/* <Nav.Toggle> */}
                                <Link to={`locative-investment`} onClick={onLinkClick}>
                                    Investissement Locatif
                                </Link>
                                {/* </Nav.Toggle> */}
                                {/* </Nav.Link> */}
                            </Nav.Item>

                            <Nav.Item>
                                {/* <Nav.Link > */}
                                <Link to={`investir`} onClick={onLinkClick}>
                                    Investir
                                </Link>
                                {/* </Nav.Link> */}
                            </Nav.Item>

                            <Nav.Item>
                                {/* <Nav.Link> */}
                                <Link to={`faq`} onClick={onLinkClick}>
                                    FAQ
                                </Link>
                                {/* </Nav.Link> */}
                            </Nav.Item>

                            <Nav.Item className='contact-us-item'>
                                <Nav.Link className='contact-us' >
                                    <Link to={`contact-us`} onClick={onLinkClick}>
                                        Nous contacter
                                    </Link>
                                </Nav.Link>
                            </Nav.Item>


                        </Nav>
                    ))}
                </div>
            </div>
        </>
    )
}

export default HeaderSecond