import React, { useState } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap';
// import Img from 'react-optimized-image';
import CardHeader from 'react-bootstrap/esm/CardHeader';
import Form from 'react-bootstrap/Form';
import { Helmet } from 'react-helmet';
import { aboutRIVData, rivAffiliateProgram, rivAffiliateProgramBase64 } from '../../../data/aboutRiv';
import './RivAffiliateProgram.scss';

// import Recto from 'https://defmaks.com/wp-content/uploads/2022/10/RIV_8-recto.jpg';
// import Verso from 'https://defmaks.com/wp-content/uploads/2022/10/RIV_9-verso.jpg';

function RivAffiliateProgram() {
  const options = [
    { value: '', text: '--Choose an option--' },
    { value: 'cd', text: 'Republique Démocratique du Congo' },
    { value: 'cg', text: 'Republique du Congo' },
    { value: 'co-za', text: 'Afrique du Sud' },
    { value: 'ang', text: 'Angola' },
  ];

  const [selected, setSelected] = useState(options[0].value);

  const handleChange = event => {
    console.log(event.target.value);
    setSelected(event.target.value);
  };

  // const img1 = 'https://defmaks.com/wp-content/uploads/2022/10/RIV_8-recto.jpg';
  // const img2 = 'https://defmaks.com/wp-content/uploads/2022/10/RIV_9-verso.jpg';

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Programme d’affiliation RIV | Rental Investissement - Virtuel</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
      </Helmet>
      <section>
        <Container>
          <h1>Programme d’affiliation RIV</h1><br />
        </Container>

        <Container>

          <Row>

            {rivAffiliateProgramBase64.map((data, key) => {
              // console.log(key)
              return (
                <Col xs={6} key={key} >
                  <img className='w-100' src={data.base} alt={data.title} />
                </Col>
              );
            })}

            {/* <Row className='d-none d-md-block my-5 mx-5'>
            {rivAffiliateProgramBase64.map((data, key) => {
              // console.log(key)
              return (
                <Col lg={6} key={key} >
                  <img className='w-100' src={data.base} alt={data.title} />
                </Col>
                );
              })}
          </Row>

          <Row className='d-md-none'>
            {rivAffiliateProgramBase64.map((data, key) => {
              // console.log(key)
              return (
                <Col key={key}  sm={6} className='mb-2'>
                  <img className='w-100' src={data.base} alt={data.title} />
                </Col>
                );
              })} */}
          </Row>
          <br />



          {rivAffiliateProgram.map((data, key) => {
            // console.log(key)
            return (
              <div key={key}>
                <div dangerouslySetInnerHTML={{ __html: data.content }}></div>
              </div>
            );
          })}

          <div>

            <p>
              N.B : Les coordonnées bancaires sont :
            </p>
            <Form.Select aria-label="Default select example" value={selected} onChange={handleChange}>
              {options.map(option => (
                <option key={option.value} value={option.value}>
                  {option.text}
                </option>
              ))}
            </Form.Select>

            <div className='bank-details-area'>

              {selected == 'ang' &&
                <>
                  <br />
                  <Row>
                    <h6>Bientôt disponible</h6>
                  </Row>
                </>
              }

              {selected == 'cg' &&
                <>
                  <br />
                  <Row>
                    <h6>Bientôt disponible</h6>
                  </Row>
                </>
              }

              {selected == 'cd' &&
                <>
                  <br />

                  <Row className='m-0 p-0'>
                    <Card className='p-0'>
                      <CardHeader>
                        <strong>BANKING DETAILS</strong>
                      </CardHeader>
                      <Row>
                        <div className='details-rows px-4 py-2'>

                          <Row>
                            <Col md={2} >Beneficiary</Col>
                            <Col>LA GENERALE DES AFFAIRES  "GENERAFF"</Col>
                          </Row>
                          <Row>
                            <Col md={2} >Account</Col>
                            <Col>00018005001200000020326</Col>
                          </Row>
                          <Row>
                            <Col md={2} >Bank</Col>
                            <Col>EQUITY BANK  CONGO SA</Col>
                          </Row>
                          <Row>
                            <Col md={2} >Address</Col>
                            <Col>4B, AVENUE DES AVIATEURS, KINSHASA/GOMBE. REPUBLIQUE DEMOCRATQUE DU CONGO</Col>
                          </Row>
                          <Row>
                            <Col md={2} >Swift</Col>
                            <Col>BCDCCDKI</Col>
                          </Row>
                        </div>
                      </Row>
                    </Card>
                  </Row>
                </>
              }

              {selected == 'co-za' &&
                <>
                  <br />
                  <Row>
                    <h6>Bientôt disponible</h6>
                  </Row>
                </>
              }
            </div>
          </div>
          <div className='spacer'></div>
        </Container>
      </section>
    </>
  )
}

export default RivAffiliateProgram