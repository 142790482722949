import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import InvestForm from '../../../components/parts/InvestForm'

function Investir() {
    return (
        <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Investir | Rental Investissement - Physique</title>
          {/* <link rel="canonical" href="http://mysite.com/example" /> */}
        </Helmet>
            <section id='faq'>
                <Container className='w-90'>
                    <h1>Investir <br /> </h1>
                    <p className=''>
                        <small>Tous les champs sont requis</small>
                    </p>
                    <p></p>

                    <Row>
                        <Col id='form-col'>
                            <InvestForm />
                        </Col>
                        <Col md="4"></Col>
                    </Row>

                </Container>
            </section>
        </>
    )
}

export default Investir