import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet';
import './InvestissementLocatif.scss';

const InvestissementLocatif = () => {

    return (
        <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Investissement Locatif | Rental Investissement - Physique</title>
          {/* <link rel="canonical" href="http://mysite.com/example" /> */}
        </Helmet>
            <section id='faq'>
                <Container className='w-90'>

                <div className='page-content'>
                    <h5>En quoi consiste l’investissement locatif ?</h5>
                    <p>
                        L’investissement locatif consiste à acheter un bien immobilier en vue de le louer pour percevoir des revenus supplémentaires.
                    </p>
                    <p>
                        En investissant dans l’immobilier locatif, chaque investisseur à ses propres objectifs. Il peut décider de faire un investissement locatif afin <strong>d’augmenter ses revenus</strong>.
                        En effet, entre la défiscalisation et le rendement locatif d’un tel placement, il sera possible, selon le calcul et l’emplacement du bien immeuble, d’assurer un revenu mensuel substantiel.
                        Ce placement immobilier va également permettre de maintenir son pouvoir d’achat.
                        De plus, ces revenus complémentaires offriront de meilleures perspectives d’avenir. 
                        <strong> Pour un retraité, l’investissement locatif lui permettra de mieux vivre, le loyer perçu mensuellement devenant ainsi un complément indispensable sur ses revenus amoindris.</strong>
                    </p>
                    <p>
                        Donc, <strong>Rental Investment</strong> vous aidera à augmenter vos revenus substantiellement en investissant dans l’immobilier locatif en <strong>Afrique du Sud à des prix hors du commun</strong>.
                    </p>
                    <br />

                    <h5>Procédure</h5>
                    <Row id='proc-steps'>
                        <Col md={4} className="step">
                            <h6>
                                Étape 1: L'analyse
                            </h6>
                            <div className='step-content'>
                                <ul>
                                    <li>Accès à Rental Investment (site internet)</li>
                                    <li>Envoi du besoin par e-mail (client)</li>
                                    <li>Validation du besoin par e-mail (RI)</li>
                                    <li>Confirmation du mandat par e-mail (client) </li>
                                </ul>
                            </div>
                        </Col>

                        <Col md={4} className="step">
                            <h6>
                                Étape 2: La recherche
                            </h6>
                            <div className='step-content'>
                                <ul>
                                    <li>Sélections des biens immobiliers</li>
                                    <li>Visite physique des biens immobiliers (facultatif)</li>
                                    <li>Offres et négociation</li>
                                    <li>Signature de la promesse de vente</li>
                                </ul>
                            </div>
                        </Col>

                        <Col md={4} className="step">
                            <h6>
                                Étape 3: L'achat
                            </h6>
                            <div className='step-content'>
                                <ul>
                                    <li>Préparation de l'acte authentique (Avocat)</li>
                                    <li>Signature de l'acte authentique (Avocat et client)</li>
                                    <li>Remise/transfert des clés (RI et client)</li>
                                </ul>
                            </div>
                        </Col>

                    </Row>
                </div>
                </Container>
            </section>
        </>
    )
}

export default InvestissementLocatif