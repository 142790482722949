// import React from 'react'
import './Header.scss';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';

function Header() {

    // const history = useNavigate();
    let location = useLocation();

    // React.useEffect(() => {
    //     trackPageView(); // To track the first pageview upon load
    //     history.listen(trackPageView); // To track the subsequent pageviews
    // }, [history]);
    React.useEffect(() => {
        // Google Analytics
        ReactGA.ga('send', 'pageview');
    }, [location]);

    function trackPageView() {
        console.log("PageView Event", window.location.pathname);
    }




    // const logo = require('../assets/logo.png')
    const logo = require('../assets/logo-white.png')
    const [showTopBtn, setShowTopBtn] = useState(false);
    const [expanded, setExpanded] = useState(false);


    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 400) {
                setShowTopBtn(true);
            } else {
                setShowTopBtn(false);
            }
        });
    }, []);
    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        // setToggle(!toggle)
    };

    const hideMenu = () => {
        setExpanded(false)
    }

    const [title, setTitle] = useState("Default Title");

    useEffect(() => {
        // This will run when the page first loads and whenever the title changes
        document.title = title;
    }, [title]);

    const changeTitle = (event) => {
        setTitle(event.target.value);
    };

    const onLinkClick = () => {
        goToTop();
        // hideMenu();
        // ()=>gaEventTracker('call')
    }


    // const handleClose = () => setShow(false);
    console.log(location);

    return (
        <>
            {/* NAVBAR IF NOT PHONE */}
            <div className='d-none d-md-block navbar-block navbar-wide'>
                {['lg'].map((expand) => (
                    <Navbar id="navbar" key={expand} bg="dark" sticky="top" expand={expand} className="mb-0 px-10 shadow-sm navbar-custom">
                        <Container fluid className='navigator'>
                            <Link to={`./`} onClick={goToTop}>
                                <Navbar.Brand>
                                    {/* Rental Investment */}
                                    <img src={logo} /> Rental Investment

                                </Navbar.Brand>
                            </Link>
                            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
                            <Navbar.Offcanvas
                                id={`offcanvasNavbar-expand-${expand}`}
                                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                                placement="end"
                            // onHide={handleClose}
                            >
                                <Offcanvas.Header closeButton>
                                    <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                        Rental Investment
                                    </Offcanvas.Title>
                                </Offcanvas.Header>
                                <Offcanvas.Body>
                                    <Nav className="justify-content-end flex-grow-1 pe-3p">
                                        <NavDropdown
                                            title="A propos de nous"
                                            id={`offcanvasNavbarDropdown-expand-${expand}`}
                                            className='about-drop'
                                        >

                                            <NavDropdown.Item >
                                                <Link to={`our-mission`} onClick={goToTop}>
                                                    Notre Mission
                                                </Link>
                                            </NavDropdown.Item>

                                            <NavDropdown.Item>
                                                <Link to={`our-objectives`} onClick={goToTop}>
                                                    Nos Objectifs
                                                </Link>
                                            </NavDropdown.Item>

                                            <NavDropdown.Item >
                                                <Link to={`our-services`} onClick={goToTop}>
                                                    Nos Services
                                                </Link>
                                            </NavDropdown.Item>
                                        </NavDropdown>

                                        <Nav.Item>
                                            <Nav.Link onClick={() => (this.expand = '')}>
                                                <Link to={`locative-investment`} onClick={goToTop}>
                                                    Investissement Locatif
                                                </Link>
                                            </Nav.Link>
                                        </Nav.Item>

                                        <Nav.Item>
                                            <Nav.Link >
                                                <Link to={`investir`} onClick={goToTop}>
                                                    Investir
                                                </Link>
                                            </Nav.Link>
                                        </Nav.Item>

                                        <Nav.Item>
                                            <Nav.Link>
                                                <Link to={`faq`} onClick={goToTop}>
                                                    FAQ
                                                </Link>
                                            </Nav.Link>
                                        </Nav.Item>


                                        <Nav.Item>
                                            <Nav.Link className='contact-us' >
                                                <Link to={`contact-us`} onClick={goToTop}>
                                                    Nous contacter
                                                </Link>
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Offcanvas.Body>
                            </Navbar.Offcanvas>
                        </Container>
                    </Navbar>
                ))}
            </div>


            {/* NAVBAR IF PHONE */}
            <div className='d-md-none navbar-block navbar-device'>
                {['md'].map((expand) => (
                    <Navbar key={expand} bg="light" sticky="top" expand={expand} className="mb-0 px-10 shadow-sm">
                        <Container fluid className='navigator'>
                            <Link to={`./`} onClick={goToTop}>
                                <Navbar.Brand>
                                    {/* Rental Investment */}
                                    <img src={logo} />Rental Investment
                                </Navbar.Brand>
                            </Link>
                            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
                            <Navbar.Offcanvas
                                id={`offcanvasNavbar-expand-${expand}`}
                                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                                placement="end"
                            >
                                <Offcanvas.Header closeButton>
                                    <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                        Rental Investment
                                    </Offcanvas.Title>
                                </Offcanvas.Header>
                                <Offcanvas.Body>
                                    <Nav className="justify-content-end flex-grow-1 pe-3p">
                                        <NavDropdown
                                            title="A propos de nous"
                                            id={`offcanvasNavbarDropdown-expand-${expand}`}
                                            className='about-drop'
                                        >

                                            <NavDropdown.Item >
                                                {/* <Link to={`our-mission`} onClick={goToTop; hideMenu}> */}
                                                <Link to={`our-mission`} onClick={onLinkClick}>
                                                    Notre Mission
                                                </Link>
                                            </NavDropdown.Item>

                                            <NavDropdown.Item >
                                                <Link to={`our-objectives`} onClick={goToTop}>
                                                    Nos Objectifs
                                                </Link>
                                            </NavDropdown.Item>

                                            <NavDropdown.Item >
                                                <Link to={`our-services`} onClick={goToTop}>
                                                    Nos Services
                                                </Link>
                                            </NavDropdown.Item>
                                        </NavDropdown>

                                        <Nav.Item>
                                            {/* <Nav.Link > */}
                                            {/* <Nav.Toggle> */}
                                            <Link to={`locative-investment`} onClick={goToTop}>
                                                Investissement Locatif
                                            </Link>
                                            {/* </Nav.Toggle> */}
                                            {/* </Nav.Link> */}
                                        </Nav.Item>

                                        <Nav.Item>
                                            {/* <Nav.Link > */}
                                            <Link to={`investir`} onClick={goToTop}>
                                                Investir
                                            </Link>
                                            {/* </Nav.Link> */}
                                        </Nav.Item>

                                        <Nav.Item>
                                            {/* <Nav.Link> */}
                                            <Link to={`faq`} onClick={goToTop}>
                                                FAQ
                                            </Link>
                                            {/* </Nav.Link> */}
                                        </Nav.Item>


                                        <Nav.Item>
                                            <Nav.Link className='contact-us' >
                                                <Link to={`contact-us`} onClick={goToTop}>
                                                    Nous contacter
                                                </Link>
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Offcanvas.Body>
                            </Navbar.Offcanvas>
                        </Container>
                    </Navbar>
                ))}
            </div>
        </>
    )
}

export default Header