import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './Footer.scss';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';

function Footer() {
    const logo = require('../assets/logo-white.png')

    const facebook_icon = require('../assets/icon/facebook.png')
    const twitter_icon = require('../assets/icon/twitter.png')
    const [showTopBtn, setShowTopBtn] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 400) {
                setShowTopBtn(true);
            } else {
                setShowTopBtn(false);
            }
        });
    }, []);
    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    return (
        <div className='footer'>
            <div className='h-48'>
                <div className='min-h-168'>
                    <Container>
                        <Row>
                            <Col id='footer-1' sm={12} md={4} className=''>
                                <h4 className='text-center font-thin'>
                                    <img src={logo} />
                                    {/* Rental Investment */}
                                    </h4>
                                <div className='text-sm mb-8'>
                                    <div className='social-buttons text-center py-3'>

                                        <Button variant="light" size="sm" href="https://facebook.com/profile.php?id=100087450473275">
                                            {/* <i className="fa-brands fa-facebook-f"></i> */}
                                            {/* <img src={facebook_icon} className="img-icon" /> */}
                                            <FaFacebookF />
                                        </Button>{' '}
                                        {/* <Button variant="light" size="sm"> */}
                                            {/* <i className="fa-brands fa-twitter"></i> */}
                                            {/* <img src={twitter_icon} className="img-icon" /> */}
                                            {/* <FaInstagram />
                                        </Button> */}
                                    </div>
                                    <div className='px-2'>
                                        <small>
                                            {/* <i className="fa-solid fa-location-dot"></i> 28, Amandelboom Street, Zwartkop Ext.4, Centurion. Pretoria */}
                                        </small>
                                    </div>
                                </div>
                            </Col>
                            <Col id='footer-2' sm={12} md={4} className='mb-8 text-sm footer-text'>
                                <h4>
                                    En quoi consiste l’investissement locatif ?
                                </h4>
                                <p>
                                    L’investissement locatif consiste à acheter un bien immobilier (appartement ou maison) en vue de le louer à un tier, dans l'objectif de percevoir des revenus supplémentaires mensuellement.
                                </p>

                            </Col>
                            <Col id='footer-3' sm={12} md={4} className='mb-8 text-center'>
                                <div>
                                    <div className="mb-5">
                                        <div className='footer-extra-links'>
                                            <h4>
                                                Liens Utiles
                                            </h4>

                                            <div>
                                                <Link to={`policy`} onClick={goToTop}>Politique de Confidentialité</Link>
                                            </div>
                                            <div>
                                                <Link to={`../`} onClick={goToTop}>Portail</Link>
                                            </div>
                                            <div>
                                            <Link to={`../rental-virtual`} onClick={goToTop}>Rental Investment Virtuel</Link>
                                            </div>
                                        </div>
                                            <br />
                                            <div class="social">
                                                <div class="title">
                                                    <strong>Download it from</strong>
                                                </div>
                                                <div>
                                                    <a href='#'>
                                                        <img src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png" width="140" height="auto" />
                                                    </a>
                                                </div>
                                                <div>
                                                    <a href="#">
                                                        <img src="https://apple-resources.s3.amazonaws.com/media-badges/download-on-the-app-store/black/en-us.svg" width="140" height="auto" />
                                                    </a>
                                                </div>

                                            </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>

            <Container className='text-center text-xs'>
                <hr />
                <small>
                    &copy; copyright 2022
                </small>
            </Container>
        </div>
    )
}

export default Footer