import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { Outlet } from 'react-router-dom';
import FooterRIV from '../../components/FooterRIV';
import HeaderRIV from '../../components/HeaderRIV';
import HeaderSecondRIV from '../../components/HeaderSecondRIV';
// import ComingSoon from '../ComingSoon';
// import './VirtualRental.scss'

function VirtualRental() {
    const isComingSoon = true;
    const [showTopBtn, setShowTopBtn] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 400) {
                setShowTopBtn(true);
            } else {
                setShowTopBtn(false);
            }
        });
    }, []);
    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Rental Investissement - Virtuel</title>
                {/* <link rel="canonical" href="http://mysite.com/example" /> */}
            </Helmet>
            {/* <ComingSoon /> */}
            <div className="App">
                <div className='d-none d-md-block navbar-block navbar-wide'>
                    <HeaderRIV />
                </div>

                {/* NAVBAR IF PHONE */}
                <div className='d-md-none navbar-block navbar-device'>
                    <HeaderSecondRIV />
                </div>
                <div className='main' >
                    <Outlet />
                </div>
                <FooterRIV />
            </div>
        </>
    )
}

export default VirtualRental