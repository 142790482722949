// import React from 'react'
import './HeaderSecond.scss';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';

function HeaderSecondRIV() {
    const logo = require('../assets/logo.png')
    const [showTopBtn, setShowTopBtn] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 400) {
                setShowTopBtn(true);
            } else {
                setShowTopBtn(false);
            }
        });
    }, []);
    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    const openNav = () => {
        document.getElementById("mySidenav").style.width = "250px";
        // document.body.style.backgroundColor = "rgba(0,0,0,0.4)";
        console.log('Nav opened');
    }

    const closeNav = () => {
        document.getElementById("mySidenav").style.width = "0";
        // document.body.style.backgroundColor = "white";
        console.log('Nav closed');
    }

    const onLinkClick = () => {
        goToTop();
        closeNav();
        // hideMenu();
        // ()=>gaEventTracker('call')
    }


    return (
        <>
            {/* NAVBAR IF NOT PHONE */}
            <span className='sm-logo-menu'>
                <img className='' src={logo} /> <span>Rental Investment Virtuel</span>
            </span>
            <span className='butn-toggle' onClick={openNav}>&#9776;</span>
            <div id="mySidenav" class="sidenav">
                <a href="javascript:void(0)" class="closebtn" onClick={closeNav}>&times;</a>

                {['md'].map((expand) => (
                    <Nav defaultActiveKey="/" className="justify-content-end flex-column">


                        <Nav.Item>
                            {/* <Nav.Link > */}
                                <Link to={`rental-investment-virtual`} onClick={onLinkClick}>
                                    A propos
                                </Link>
                            {/* </Nav.Link> */}
                        </Nav.Item>

                        <Nav.Item>
                            {/* <Nav.Link > */}
                                <Link to={`advantages`} onClick={onLinkClick}>
                                    Avantages
                                </Link>
                            {/* </Nav.Link> */}
                        </Nav.Item>

                        <Nav.Item>
                            {/* <Nav.Link > */}
                                <Link to={`riv-affiliate-program`} onClick={onLinkClick}>
                                    Programme d’affiliation RIV
                                </Link>
                            {/* </Nav.Link> */}
                        </Nav.Item>

                        <Nav.Item>
                            {/* <Nav.Link> */}
                                <Link to={`capital-credit-and-repayment`} onClick={onLinkClick}>
                                    Paiement, Crédit et Remboursement
                                </Link>
                            {/* </Nav.Link> */}
                        </Nav.Item>

                    </Nav>
                ))}
            </div>
        </>
    )
}

export default HeaderSecondRIV