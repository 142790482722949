import React from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import UseAnalyticsEventTracker from '../services/UseAnalyticsEventTracker'
import './Landing.scss'
import ReactGA from 'react-ga';
import {Helmet} from "react-helmet";
// import PhysicRental from './PhysicRental'
// import { Col, Container, Row } from 'react-bootstrap'


function Landing() {
  const logo = require('../assets/logo.png')
  const gaEventTracker = UseAnalyticsEventTracker('Portail');
  ReactGA.pageview(window.location.pathname + window.location.search);
  console.log(window.location.pathname + window.location.search);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Portail | Rental Investissement</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
      </Helmet>
      <div className="wrapper">
        <main className="page-main">
          <div className='language-bar'>
            <Row>
              <Col>
                <img src={logo} />
              </Col>
              <Col>
                <Button className='selected lang-fr'>

                </Button>
                <Button disabled className='lang-en'>

                </Button>
              </Col>
            </Row>
          </div>
          <div className='blocs'>
            <Row>
              <Col lg={12} className='real-rental-bloc'>
                <Card>
                  <Link to={`rental-real`}>
                    <Card.Img variant="top" src={require('../assets/0735223e-3f2c-40e5-89b9-9a6887cf0f19.jpg')} />
                  </Link>
                </Card>
              </Col>

              <Col lg={12}>
                <div className='text-center'>
                  <Button className='btn-item btn-inv' href={`rental-real`}>
                    Rental Investment Physique
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
          <div className='blocs'>
            <Row>
              <Col lg={12} className='real-rental-bloc'>
                <Card>
                  <Link to={`rental-virtual`}>
                  <Card.Img variant="top" src={require('../assets/ace80429-30bb-4717-87e6-70ff886b132b.jpg')} />
                  </Link>
                </Card>
              </Col>

              <Col lg={12}>
                <div className='text-center'>
                  <Button className='btn-item btn-inv' href={`rental-virtual`}>
                    Rental Investment Virtuel
                  </Button>
                </div>
              </Col>
            </Row>

          </div>

        </main>
      </div>

    </>
  )
}

export default Landing