export const faqData = [
    {
        question: 'Qu’est-ce que Rental Investment ?',
        answer: "Rental Investment est un service immobilier dédié aux non-résidents, Sud-Africains et étrangers, leur permettant d'investir en Afrique du Sud sans se déplacer forcément. Vous n’avez rien à faire sinon prendre des décisions et nous laisser faire le reste.",
    },
    {
        question: 'Comment ça marche ?',
        answer: 'Vous nous expliquez votre projet en détails, nous analysons votre capacité financière et la faisabilité dudit projet, puis nous organisons le reste pour vous : recherches, visites et signatures chez le notaire.',
    },
    {
        question: 'Comment communiquer avec Rental Investment ?',
        answer: 'Trois moyens sont mis à votre disposition pour entrer en contact avec nous : <br><li>mail : <a href="mailto:contact@rental-investment.com">contact@rental-investment.com</a></li> <li>téléphone : <a href="tel:+27607923455">+27607923455</a></li> <li>ou Whatsapp : <a href="https://wa.me/+27678066072">+27678066072</a>.</li><br>',
    },
    {
        question: 'Quel type d’investissement Rental Investment met à la disposition de sa clientèle ?',
        answer: 'Rental Investment est spécialisé dans l’investissement locatif en Afrique du Sud. Pour le moment, Investment est implanté à Johannesburg, Pretoria, Durban, Cap Town et Port Elisabeth. Nous proposons des biens dans ces grandes villes ainsi qu’en périphérie de celles-ci.',
    },
    {
        question: 'Qui sera votre interlocuteur privilégié ?',
        answer: 'Lorsque votre projet démarre, votre contact commercial est votre interlocuteur référent. Votre projet est ensuite géré et organisé par l’un de nos chargés de projet qui devient alors votre interlocuteur privilégié.',
    },
    {
        question: 'Qui sont les partenaires de Rental Investment ?',
        answer: '<strong>Rental Investment</strong> s’est entouré de nombreux partenaires à chacune des étapes d’un projet immobilier : agences, notaires, avocats, courtiers, agences de gestion locative, artisans et architectes d’intérieur.',
    },
    {
        question: "Outre le montant de l'achat, quels autres types de frais sont exigés ?",
        answer: 'Il existe plusieurs types de frais lors d’un achat immobilier en Afrique du Sud. Rental Investment vous fournira toutes les informations y relatives',
    },
]