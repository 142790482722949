import React from 'react'
import { Col, Container, ListGroup, Row } from 'react-bootstrap'
import GetQuoteForm from '../../../components/parts/GetQuoteForm';
import './ContactUs.scss';
import { FaPhoneAlt, FaWhatsapp } from 'react-icons/fa';
import { Helmet } from 'react-helmet';


const ContactUs = () => {

    return (
        <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Nous contacter | Rental Investissement - Réel</title>
          {/* <link rel="canonical" href="http://mysite.com/example" /> */}
        </Helmet>
            <section id='contact-us'>
                <div className='header-wide'>
                    <h1>Nous Contacter </h1>
                </div>
                <Container className='w-90'>
                    <br />
                    <h5 className='text-align-center'>Écrivez nous en remplissant ce formulaire
                    <br />
                    </h5>
                    <p className='text-align-center'>
                        <small>Tous les champs sont requis</small>
                    </p>
                    <p></p>
                    <Row>
                        <Col md="4"></Col>
                        <Col id='form-col'>
                            {/* <InvestForm /> */}
                            <GetQuoteForm />

                            <hr />

                        </Col>
                        <Col md="4"></Col>
                    </Row>

                    <Row>
                        <Col md="4"></Col>
                        <Col id='other-contact-col'>
                            <h5 className='text-align-center'> Ou Contact téléphonique:</h5>
                            <ListGroup className='contact-list'>
                                <ListGroup.Item>
                                    <FaPhoneAlt /> : <a href='tel:+27607923455'>+27607923455</a>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <FaWhatsapp /> : <a href='https://wa.me/+27678066072'>+27678066072</a>
                                </ListGroup.Item>
                            </ListGroup>
                        </Col>

                        <Col md="4"></Col>
                    </Row>

                </Container>
            </section>
        </>
    )
}

export default ContactUs