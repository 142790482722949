import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { advData } from '../../../data/aboutRiv'

function Advantages() {
    return (
        <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Avantages | Rental Investissement - Virtuel</title>
          {/* <link rel="canonical" href="http://mysite.com/example" /> */}
        </Helmet>
            <section>
                <Container>
                    <h1>Avantages</h1><br />
                </Container>

                <Container>
                    <Row>
                        <Col>
                            {advData.map((data, key) => {
                                console.log(key)
                                return (
                                    <div key={key}>
                                        {/* <h5 > {data.question} </h5> */}
                                        <div dangerouslySetInnerHTML={{ __html: data.answer }}></div>
                                    </div>
                                );
                            })}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {advData.map((data, key) => {
                                console.log(key)
                                return (
                                    <div key={key}>
                                        {/* <h5 > {data.question} </h5> */}
                                        <div dangerouslySetInnerHTML={{ __html: data.answer2 }}></div>
                                    </div>
                                );
                            })}
                        </Col>
                        <Col>
                            <img className='w-100' src={require('../../../assets/shutterstock_1412926562.jpg')} />
                        </Col>
                    </Row>
                </Container>
                <div className='spacer'></div>
            </section>
        </>
    )
}

export default Advantages