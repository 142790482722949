import React, { useState } from 'react'
import { Container } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { aboutRIVData } from '../../../data/aboutRiv'

function RentalInvestmentVirtual() {
    return (
        <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>A propos | Rental Investissement - Virtuel</title>
          {/* <link rel="canonical" href="http://mysite.com/example" /> */}
        </Helmet>
            <section>
                <Container>
                    <h1>A propos de Rental Investment Virtuel</h1><br />
                </Container>

                <Container>
                    {aboutRIVData.map((data, key) => {
                        console.log(key)
                        return (
                            <div key={key}>
                                <h5 > {data.question} </h5>
                                <div dangerouslySetInnerHTML={{ __html: data.answer }}></div>

                                {data.img &&
                                    <div className='text-center'>
                                        <img src={data.img} width='290px' />
                                    </div>
                                }
                            </div>
                        );
                    })}

                </Container>
                <div className='spacer'></div>
            </section>
        </>
    )
}

export default RentalInvestmentVirtual