// import React from 'react'
import './Header.scss';
import './HeaderRIV.scss';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';

function HeaderRIV() {
    const logo = require('../assets/logo.png')
    const [showTopBtn, setShowTopBtn] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 400) {
                setShowTopBtn(true);
            } else {
                setShowTopBtn(false);
            }
        });
    }, []);
    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };


    return (
        <>
            {['sm'].map((expand) => (
                <Navbar key={expand} bg="dark" sticky="top" expand={expand} className="mb-0 px-10 shadow-sm navbar-riv">
                    <Container fluid className='navigator'>
                        <Link to={`./`} onClick={goToTop}>
                            <Navbar.Brand>
                                {/* Rental Investment */}
                                {/* <img src={logo} /> <span className='d-none d-sm-block'>Rental Investment Virtuel</span> */}

                                <img className='' src={logo} /> <span style={{color:'white'}}>Rental Investment Virtuel</span>
                            </Navbar.Brand>
                        </Link>
                        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
                        <Navbar.Offcanvas
                            id={`offcanvasNavbar-expand-${expand}`}
                            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                            placement="end"
                        >
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                    Rental Investment Virtuel
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <Nav className="justify-content-end flex-grow-1 pe-3p">
                                    {/* <NavDropdown
                                        title="A propos de nous"
                                        id={`offcanvasNavbarDropdown-expand-${expand}`}
                                        className='about-drop'
                                    >

                                        <NavDropdown.Item >
                                            <Link to={`our-mission`} onClick={goToTop}>
                                                Notre Mission
                                            </Link>
                                        </NavDropdown.Item>

                                        <NavDropdown.Item >
                                            <Link to={`our-objectives`} onClick={goToTop}>
                                                Nos Objectifs
                                            </Link>
                                        </NavDropdown.Item>

                                        <NavDropdown.Item >
                                            <Link to={`our-services`} onClick={goToTop}>
                                                Nos Services
                                            </Link>
                                        </NavDropdown.Item>
                                    </NavDropdown> */}

                                    <Nav.Item>
                                        <Nav.Link >
                                            <Link to={`rental-investment-virtual`} onClick={goToTop}>
                                                A propos
                                            </Link>
                                        </Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item>
                                        <Nav.Link >
                                            <Link to={`advantages`} onClick={goToTop}>
                                                Avantages
                                            </Link>
                                        </Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item>
                                        <Nav.Link >
                                            <Link to={`riv-affiliate-program`} onClick={goToTop}>
                                                Programme d’affiliation RIV
                                            </Link>
                                        </Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item>
                                        <Nav.Link>
                                            <Link to={`capital-credit-and-repayment`} onClick={goToTop}>
                                                Paiement, Crédit et Remboursement
                                            </Link>
                                        </Nav.Link>
                                    </Nav.Item>


                                    {/* <Nav.Item>
                                        <Nav.Link className='contact-us' >
                                            <Link to={`contact-us`} onClick={goToTop}>
                                                Nous contacter
                                            </Link>
                                        </Nav.Link>
                                    </Nav.Item> */}
                                </Nav>
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                    </Container>
                </Navbar>
            ))}
        </>
    )
}

export default HeaderRIV