import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import { Navigation, Pagination, Autoplay, EffectFade } from 'swiper';

import './MultiCarousel.scss';

import { FaDollarSign } from 'react-icons/fa';


const config = {
  base: 'appwMEXsNdiApbdAI',
  apiKey: 'keytFNNyh6QrMurDu'
}


const request = new Request(`https://api.airtable.com/v0/${config.base}/Houses`, {
  method: 'get',
  headers: new Headers({
    'Authorization': `Bearer ${config.apiKey}`
  })
});

class MultiCarousel extends React.Component {

  constructor(props) {
    super(props);
    this.state = { records: [] };
    this.fetchAirtable = this.fetchAirtable.bind(this);
  }

  async componentDidMount() {
    await this.fetchAirtable()
  }

  async fetchAirtable() {
    var resp = await fetch(request).catch(err => { console.log(err) })
    if (resp.status >= 200 && resp.status < 300) {
      var json = await resp.json()
      const { records } = json;
      this.setState({ records });
    }
  }

  render() {
    var { records } = this.state
    return (
      <>
        <Swiper
          // install Swiper modules
          slidesPerView={3}
          initialSlide={1}
          spaceBetween={10}
          centeredSlides={true}
          // Disable preloading of all images
          preloadImages={false}
          // Enable lazy loading
          lazy={true}
          loop={true}
          autoplay={{
            delay: 6500,
            disableOnInteraction: true,
            pauseOnMouseEnter: true,
          }}
          pagination={{
            clickable: true,

          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation, EffectFade]}
          className="mySwiper"
          // effect="fade"
          // virtual
        // onSwiper={(swiper) => console.log(swiper)}
        // onSlideChange={() => console.log('slide change')}
        >



          {records.map((house, key) =>
            <SwiperSlide id={house.id} key={key} >
              <div className="card">
                <img
                  src={house.fields.HouseCover[0].thumbnails.large.url} 
                  alt="First slide"
                  className="card-img-top" />

                <div className="card-body">
                  <div className="card-text text-center">
                    <span className='price'> {house.fields.PrixAchat} $</span>
                  </div>
                </div>

                <div class="card-footer text-muted">
                  <div className='location text-center'>
                    Loyer : {house.fields.Loyer} $
                    {/* <i className="fa-solid fa-location-dot"></i> {house.fields.Loyer} USD */}
                  </div>
                </div>
              </div>
            </SwiperSlide>
          )}




        </Swiper>
      </>
    )
  }
}

export default MultiCarousel