import React from 'react'
import { Container } from 'react-bootstrap';
import '../About.scss'
import data from "../../../../data/aboutUs"
import { Helmet } from 'react-helmet';

const ourMission = data.FR[2];

const OurServices = () => {
    // console.log(ourMission);
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Nos Services | Rental Investissement - Réel</title>
                {/* <link rel="canonical" href="http://mysite.com/example" /> */}
            </Helmet>

            <section id='our-mission'>
                <div className='header-wide'>
                    <h1>Nos Services</h1>
                </div>
                <Container className='w-90'>
                    <br />
                    <div dangerouslySetInnerHTML={{ __html: ourMission.services }}></div>
                    <div className='spacer'></div>
                    <div className='spacer'></div>
                    <div className='spacer'></div>
                </Container>
            </section>
        </>
    )
}

export default OurServices